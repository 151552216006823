'use client';

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';

import Button from 'components/ui/Button';
import FlipDeck from 'components/ui/FlipDeck';
import Link from 'components/ui/Link';
import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import Spinner from 'components/ui/Spinner';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import useSubscribe from 'hooks/useSubscribe';
import { EMAIL_REGEX } from 'utils';
import { event } from 'utils/gtm';

import styles from './Newsletter.module.scss';

const slides = [
    {
        entryTitle: 'Slide 1',
        image: {
            title: 'social-image-1',
            description: 'Slide 1',
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/5tozENEqtP0HwaVrke1Bxp/047dffb2581c7162ce216cedf770ef5c/newsletter-nopromo.jpg',
            width: 884,
            height: 1006,
            fileName: 'ncd-newsletter-01.jpg',
        },
        __typename: 'flipDeckSlide',
    },
    {
        entryTitle: 'Slide 2',
        image: {
            title: 'social-image-2',
            description: 'Slide 2',
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/7nW4WmaJMASVPsq09V90mG/a6a2315225a80584e550ad64029112cc/cream-cheese-newsletter-2.webp',
            width: 884,
            height: 1006,
            fileName: 'ncd-newsletter-02.jpg',
        },
        __typename: 'flipDeckSlide',
    },
    {
        entryTitle: 'Slide 3',
        image: {
            title: 'social-image-3',
            description: 'Slide 3',
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/7uwKR1bzufa66zy71FfXZF/085def96dc14fe1b145ed524f1c92630/cream-cheese-newsletter-3.webp',
            width: 884,
            height: 1006,
            fileName: 'ncd-newsletter-03.jpg',
        },
        __typename: 'flipDeckSlide',
    },
];

const Newsletter = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const { subscribe, isSubmitting, isSuccess, isError, isSubscribed } =
        useSubscribe('Mini_BAM_Generic_Cream_Cheese_23', true);

    useEffect(() => {
        if (isSuccess) {
            event('new_subscriber', {
                formLocation: 'block',
                source: 'Mini_BAM_Generic_Cream_Cheese_23',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const watched = watch();

    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                <div className={styles.contentWrapper}>
                    <Text
                        baseTheme="displayLarge"
                        themes={{
                            large: 'displayXLarge',
                            xxLarge: 'displayXXLarge',
                        }}
                        as="h2"
                        className={styles.headline}
                    >
                        Get
                        <br />
                        first dibs
                    </Text>
                    <Text
                        as="p"
                        baseTheme="bodyMedium"
                        themes={{ large: 'bodyLarge' }}
                        className={styles.subHeadline}
                        fixWidows
                    >
                        Sign up for our newsletter and you’ll be first to know
                        about new products, tasty recipes, our best offers, and
                        more.
                    </Text>

                    <form
                        onSubmit={handleSubmit(data => {
                            delete data.terms;
                            subscribe(data);
                        })}
                    >
                        <div
                            className={cx(styles.inputLockup, {
                                [styles.inputSuccess]: isSuccess,
                                [styles.loading]: isSubmitting,
                            })}
                        >
                            <Text
                                className={styles.inputWrap}
                                baseTheme="bodyMedium"
                            >
                                {isSuccess ? (
                                    <p className={styles.input}>
                                        Successfully submitted!
                                    </p>
                                ) : (
                                    <input
                                        {...register('email_address', {
                                            required: true,
                                            pattern: EMAIL_REGEX,
                                        })}
                                        className={cx(styles.input, {
                                            [styles.inputError]:
                                                errors.email_address ||
                                                isSubscribed,
                                        })}
                                        type="email"
                                        placeholder={'Enter your email'}
                                    />
                                )}
                            </Text>
                            <div className={styles.submitButtonWrap}>
                                <Button
                                    className={cx(styles.submitButton, {
                                        [styles.disabled]:
                                            !watched?.terms ||
                                            !watched?.['email_address']?.length,
                                    })}
                                    type="submit"
                                    theme="none"
                                    disabled={
                                        !watched?.terms ||
                                        !watched?.['email_address']
                                    }
                                >
                                    <span className="sr-only">Submit</span>
                                    <SvgIcon
                                        type="arrowRightRounded"
                                        className={styles.submitArrow}
                                        alt="submit"
                                    />
                                </Button>
                                <div className={styles.buttonSpinner}>
                                    <Spinner />
                                </div>
                            </div>
                        </div>
                        <div className={styles.reCaptchaLegal}>
                            <ReCaptchaLegal />
                        </div>

                        <Text
                            baseTheme="bodyXSmall"
                            as="p"
                            themes={{
                                xLarge: 'bodySmall',
                            }}
                            className={cx(styles.checkboxWrapper, {
                                [styles.hide]: isSuccess,
                            })}
                        >
                            <label
                                className={cx(styles.checkbox, {
                                    [styles.checkboxError]: errors.terms,
                                })}
                            >
                                I agree to receive emails for marketing
                                purposes. <br />
                                <Link
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Read Privacy Policy
                                </Link>
                                <input
                                    type="checkbox"
                                    {...register('terms', {
                                        required: true,
                                    })}
                                />
                                <span className={styles.checkmark} />
                            </label>
                        </Text>
                        <div className={styles.joinError}>
                            <Text
                                className={styles.joinErrorInner}
                                baseTheme="bodyXSmall"
                            >
                                {(errors.email_address || isError) &&
                                    'Email is invalid'}
                                {isSubscribed && (
                                    <>
                                        Oops, looks like you&rsquo;re already
                                        subscribed.
                                    </>
                                )}
                            </Text>
                        </div>
                    </form>
                </div>
                <div className={styles.deckWrapper}>
                    <FlipDeck flushTop flushBottom slides={slides} />
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
